<template>
  <div class="myCourse-content">
    <div class="nav-wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/participation'}">我的参与</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/participation',query:{type:'course'}}">教学活动</el-breadcrumb-item>
        <el-breadcrumb-item>{{ dataList[0].courseTitle }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="myCourse-detail">
      <h3>课程学习</h3>
      <ul class="students">
        <li v-for="(item,index) in dataList" :key="index" @click="getSelected(index)" :class="{selectedStudent:selectedStudent===index}">{{ item.name }}</li>
      </ul>
      <div class="myCourse-title">
        <div class="left">课程：{{ dataList[0].courseTitle }}</div>
        <div class="right">
          <span>学习进度：<span class="percent">{{ dataList[selectedStudent].studySchdule>100?'100':dataList[selectedStudent].studySchdule }}%</span> </span>
          <span>|</span>
          <span><i class="el-icon-edit-outline"></i>
            <el-dropdown @command="checkCanExam">
              <span class="el-dropdown-link">课程考试</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="exam">开始考试</el-dropdown-item>
                <el-dropdown-item command="scored">查看成绩</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span>|</span>
          <span><i class="el-icon-chat-dot-round"></i>联系老师</span>

        </div>
      </div>
      <template v-if="index != null">
        <h2>当前任务</h2>
        <div class="nowCourse">
          <div class="title">
            <div class="left">
              <div class="num">{{ nowList[0].sorted+1 }}</div>
              <div class="name">{{ nowList[0].title }}</div>
            </div>
            <div class="right">
              <span>学习进度：<span class="percent">{{ nowList[0].schedule>100?'100':nowList[0].schedule }}%</span> </span>
            </div>
          </div>

          <div class="steps">
            <el-steps direction="vertical" :active="step" :space="70">
              <el-step v-for="(items,_index) in nowList[0].children" :key="_index" :title="items.title" :description="items.duration"></el-step>
            </el-steps>
            <div class="btns">
              <div class="btn-item" v-for="(items,_index) in nowList[0].children" :key="_index" :class="{finish:items.schedule==100? true:false}">
                <el-button v-if="online" :disabled="!items.canSee" @click="goVideo(items.taskId,items.pid,items.materialType,items.material,items.canSee)">{{ items.materialType == 1 ? '看视频' : '下载文件' }}</el-button>
                <span v-else>{{ items.schedule == 0 ? '未学习' : '已学习' }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <h2>全部任务 <span class="class-num">（共{{ menuList.length }}节）</span></h2>
      <div class="courseAll">
        <div class="courseItem" v-for="(course,i) in menuList" :key="i">
          <div class="title" @click="fold(i)">
            <div class="left">
              <div class="num">{{ i + 1 }}</div>
              <div class="name">{{ course.title }}</div>
            </div>
            <div class="right">
              <span>学习进度：<span class="percent">{{ course.schedule>100?'100':course.schedule }}%</span> </span>
            </div>
          </div>
          <div class="steps" v-if="course.fold">
            <el-steps direction="vertical" :active="course.step" :space="70">
              <el-step v-for="(items,_index) in course.children" :key="_index" :title="items.title" :description="items.duration"></el-step>
            </el-steps>
            <div class="btns">
              <div class="btn-item" v-for="(items,_index) in course.children" :key="_index" :class="{finish:items.schedule==100? true:false}">
                <el-button v-if="online" :disabled="!items.canSee" @click="goVideo(items.taskId,items.pid,items.materialType,items.material,items.canSee)">{{ items.materialType == 1 ? '看视频' : '下载文件' }}</el-button>
                <span v-else>{{ items.schedule == 0 ? '未学习' : '已学习' }}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <exam-apply v-if="examDialog" :applyOrderId="applyPrderId" :hasExam="hasExam" ref="exam" @close="examDialog=false"/>
  </div>
</template>

<script>
import {getMyClassDetail, pushCourseTask} from '@/api/course'
import examApply from "@/pages/course/myCourse/examApply";
import {getScored} from "@/api/exam";

export default {
  components: {
    examApply
  },
  data() {
    return {
      applyPrderId: this.$route.query.applyPrderId,
      hasExam:this.$route.query.hasExam,
      activeName: 'second',
      dataList: [{courseTitle:''}],
      selectedStudent: 0,
      step: 1,
      menuList: [],
      nowList: [{sorted:''}],
      index: null,
      online: true,
      examDialog:false
    }
  },
  mounted() {
    this.init()

  },
  methods: {
    init() {
      getMyClassDetail(this.applyPrderId).then((res) => {
        if (res.code === 200) {
          this.dataList = res.data
          this.menuList = res.data[0].courseDirectorys
          if (res.data[0].courseDirectorynow&&res.data[0].courseDirectorynow.length>0) {
            this.nowList = res.data[0].courseDirectorynow
            this.index = 0
          }
          this.menuList.map((item) => {
            this.$set(item, 'step', 0)
            this.$set(item, 'fold', false)
            item.children.map((course) => {
              if (course.schedule != 0) {
                item.step++
              }
            })
          })
          this.online = res.data[0].teachingMethod != 3;
        }
      })
    },
    refreshMenu() {
      this.index = null
      getMyClassDetail(this.applyPrderId).then((res) => {
        if (res.code === 200) {
          this.dataList = res.data
          this.menuList = res.data[this.selectedStudent].courseDirectorys
          // this.nowList = res.data[this.selectedStudent].courseDirectorynow
          if (res.data[this.selectedStudent].courseDirectorynow&&res.data[this.selectedStudent].courseDirectorynow.length>0) {
            this.nowList = res.data[this.selectedStudent].courseDirectorynow
            this.index = this.selectedStudent
          }
          this.menuList.map((item) => {
            this.$set(item, 'step', 0)
            this.$set(item, 'fold', false)
            item.children.map((course) => {
              if (course.schedule != 0) {
                item.step++
              }
            })
          })
        }
      })
    },
    getSelected(index) {
      // if(this.dataList[index].courseDirectorynow&&this.dataList[index].courseDirectorynow.length>0){
      //   this.index = index
      // }
      this.selectedStudent = index
      this.refreshMenu(index)
    },
    fold(index) {
      this.menuList[index].fold = this.menuList[index].fold === false;
    },
    goVideo(taskId, menuId, type, material,canSee) {
      if(canSee){
        material = JSON.parse(material)
        let url = this.loadUrl(material[0].url)
        if (type == 1) {
          let studentData = this.dataList[this.selectedStudent]
          this.$router.push({
            path: '/videoList',
            query: {
              studentId: studentData.id,
              selectedMenu: menuId,
              selectedCourse: taskId,
              url: url
            }
          })
        } else {
          window.open(url)
          let params={
            sch:100,
            taskId:taskId
          }
          pushCourseTask(params).then((res)=>{
            if(res.code===200){
              console.log('上传成功100')
              this.refreshMenu()
            }
          })
        }
      }else {
        this.$alert('无法点击该节课，请完成前置学习', {
          confirmButtonText: '确定',
        });
      }
    },

    //考试
    checkCanExam(command) {
      if(this.hasExam){
        if(this.dataList[this.selectedStudent].studySchdule < 100){
          return this.$message({type: 'warning', message: '请先完成学习进度！'})
        }else if(this.dataList[0].isExam == 0){
          if(command==='exam'){
            return this.$message({type: 'warning', message: '该课程不需要考试！'})
          }else if(command==='scored'){
            return this.$message({type: 'warning', message: '该课程不需要考试，没有考试成绩查看！'})
          }
        }
      }else {
        return this.$message({type: 'warning', message: '不在考试时间范围内！'})
      }

      if(command==='exam'){
        this.examDialog = true
        this.$nextTick(()=>{
          this.$refs.exam.initExam()
        })
      }else {
        getScored(this.dataList[this.selectedStudent].id).then((res) => {
          if(res.code == 200){
            if(res.data.paperVos){
              this.$router.push({
                path:'/grades',
                query:{
                  applyId:this.dataList[this.selectedStudent].id,
                  applyOrderId:this.applyPrderId
                }
              })
            }else {
              return this.$message({type: 'warning', message: '该人员还没进行考试！'})
            }
          }
        })

      }

    },


  }
}
</script>

<style lang="scss" scoped>
.myCourse-content {
  height: 100%;

  .nav-wrapper {
    padding: 14px 0;
  }

  .myCourse-detail {
    background-color: #fff;
    padding: 0 20px;

    h3 {
      height: 54px;
      line-height: 54px;
      background: #FFFFFF;
      border-bottom: 1px solid #ECECEC;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    .students {
      height: 47px;
      display: flex;
      border-bottom: 1px solid #ECECEC;
      padding-top: 10px;

      li {
        padding: 12px 27px;
        cursor: pointer;

        &.selectedStudent {
          border-bottom: 1px solid #1E50AE;
          color: #1E50AE;
        }

      }
    }

    .myCourse-title {
      padding-top: 34px;
      padding-bottom: 40px;
      color: #555555;
      display: flex;
      justify-content: space-between;

      .right {
        span {
          padding-right: 12px;

          &.percent {
            padding-right: 0;
            color: #1E50AE;
          }
        }
      }
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;

      .class-num {
        font-weight: normal;
        font-size: 14px;
        color: #333333;
      }
    }

    .nowCourse {
      background: #FFFFFF;
      border: 1px solid #ECECEC;
      margin-bottom: 40px;
      margin-top: 20px;

      .title {
        padding: 27px 18px;
        background-color: #F7F7F7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .num {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #AEC0E2;
            border-radius: 50%;
            margin-right: 17px;
            font-size: 18px;
            color: #1E50AE;
          }

          .name {
            font-size: 18px;
            color: #333333;
          }
        }

        .right {
          span {
            padding-right: 12px;

            &.percent {
              padding-right: 0;
              color: #1E50AE;
            }
          }
        }

      }

      .steps {
        padding: 28px 24px;
        display: flex;
        justify-content: space-between;

        .btns {
          ::v-deep .el-button {
            color: #1E50AE;
            border-color: #1E50AE;
          }

          .btn-item {
            cursor: pointer;
            height: 70px;
            font-size: 14px;
            color: #AEC0E2;

            &.finish {
              color: #1E50AE;
            }
          }
        }
      }
    }

    .courseAll {
      padding-bottom: 70px;
      margin-top: 20px;

      .courseItem {
        background: #FFFFFF;
        border: 1px solid #ECECEC;

        .title {
          padding: 27px 18px;
          background-color: #F7F7F7;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: center;

            .num {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid #AEC0E2;
              border-radius: 50%;
              margin-right: 17px;
              font-size: 18px;
              color: #1E50AE;
            }

            .name {
              font-size: 18px;
              color: #333333;
            }
          }

          .right {
            span {
              padding-right: 12px;

              &.percent {
                padding-right: 0;
                color: #1E50AE;
              }
            }
          }

        }

        .steps {
          padding: 28px 24px;
          display: flex;
          justify-content: space-between;

          .btns {
            ::v-deep .el-button {
              color: #1E50AE;
              border-color: #1E50AE;
            }

            .btn-item {
              cursor: pointer;
              height: 70px;
              font-size: 14px;
              color: #AEC0E2;

              &.finish {
                color: #1E50AE;
              }
            }
          }
        }
      }

    }
  }


}
</style>


<template >
  <div class="dialog-wrapper">
    <!--考试弹窗-->
    <el-dialog :title="examTitle" :visible.sync="dialogVisible" width="520px" :class="{qr:examStep==3}" :before-close="handleClose">

      <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm" v-if="examStep==1">
        <el-form-item label="考试人员" :rules="[ { required: true, message: '请选择考试人员', trigger: 'change' }, ]">
          <el-select v-model="examStudent" placeholder="请选择考试人员">
            <el-option v-for="item in studentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="exam-content" v-else-if="examStep==2">
        <ul>
          <li><span class="name">姓名：</span><span class="value">{{ studentData.name }}</span></li>
          <li><span class="name">性别：</span><span class="value">{{ studentData.sex == 1 ? '男' : '女' }}</span></li>
          <li><span class="name">手机号码：</span><span class="value">{{ studentData.phone }}</span></li>
          <li><span class="name">身份证：</span><span class="value">{{ studentData.idNumber }}</span></li>
          <li><span class="name">考试课程：</span><span class="value">{{ examData.courseTitle }}</span></li>
          <li v-if="!examOrderId"><span class="name">支付金额：</span><span class="value red">￥{{ examData.fee }}</span></li>
          <li class="pay" v-if="!examOrderId">
            <span class="name">支付方式：</span>
            <el-radio-group class="pay-type-group" v-model="payType">
              <el-radio :label="1">
                <span class="iconfont icon-weixinzhifu" :class="{checked:payType==1}"></span> 微信支付
              </el-radio>
<!--              <el-radio :label="2">-->
<!--                <span class="iconfont icon-zhifubao" :class="{checked:payType==2}"></span>支付宝支付-->
<!--              </el-radio>-->
            </el-radio-group>
          </li>
        </ul>
      </div>
      <div class="qr-wrapper" v-if="examStep==3 ">
        <div class="qr-title">
          <div class="name">
            <span class="title">商品：课程考试费用</span>
          </div>
          <div class="price">
            <span class="red">{{ orderPrice }}</span>
            <span>元</span>
          </div>

        </div>
        <div class="qr-type">
          <img class="type" src="../../../assets/image/alipay.png" alt="" v-if="payType==2">
          <img class="type" src="../../../assets/image/wechat.png" alt="" v-if="payType==1">
          <img class="conner" src="../../../assets/image/coner.png" alt="">
        </div>
        <div class="qr-code">
          <VueQr draggable="false" :correctLevel="3" backgroundColor="white" :logoScale="0.2" :logoMargin="5" :margin="10" logoBackgroundColor="white" :size="168" :text="qrcode" class="qrcode"/>
        </div>
        <div class="bottom">手机扫一扫付款</div>
      </div>
      <div class="pay-result" v-if="examStep==4">
        <div class="result" v-if="payResult === 'success'">
          <span class="iconfont icon-chenggong"></span>
          <h3>支付成功 </h3>
          <span>已成功支付课程考试费用</span>
        </div>
        <div class="result" v-else-if="payType==='fail'">
          <span class="iconfont icon-shibai"></span>
          <h3> 支付失败</h3>
        </div>
      </div>

      <div class="btn dialog-footer" slot="footer" v-if="examStep==1">
        <el-button @click="handleClose" size="medium">取 消</el-button>
        <el-button type="primary" @click="next" size="medium">下一步</el-button>
      </div>
      <div class="btn dialog-footer" slot="footer" v-if="examStep==2">
        <el-button @click="prev" size="medium">上一步</el-button>
        <el-button type="primary" @click="next" size="medium" v-if="!examOrderId">下一步</el-button>
        <el-button type="primary" @click="goExam" size="medium" v-else>开始考试</el-button>
      </div>
      <div class="btn dialog-footer" slot="footer" v-if="examStep==4">
        <el-button @click="handleClose" size="medium">关闭</el-button>
        <el-button type="primary" @click="next" size="medium" v-if="payResult==='success'">开始考试</el-button>
        <el-button type="primary" @click="next" size="medium" v-else>重新报名</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {canExam, getExamOrder, startExam, getStudentList} from "@/api/exam";
import {getQrcode} from "@/api/pay";
import VueQr from "vue-qr";
import {payNative} from "../../../api/pay";

export default {
  name: "examApply",
  components: {
    VueQr
  },
  props: ['applyOrderId','hasExam'],
  data() {
    return {
      //考试
      examTitle: '请选择考试人员',
      dialogVisible: true,
      examStep: 1,
      examStudent: '',
      payType: 1,
      qrcode: '',
      studentData: null,
      examData: null,
      orderNumber: '',
      payResult: '',
      orderPrice: null,
      examOrderId: null,
      studentList: [],
      isPay:''
    }
  },
  methods: {
    //考试
    initExam() {
      this.examTitle = '请选择考试人员'
      this.dialogVisible = true
      this.examStep = 1
      this.examStudent = ''
      this.payType = 1
      this.qrcode = ''
      this.studentData = null
      this.examData = null
      this.orderNumber = ''
      this.payResult = ''
      this.orderPrice = null
      this.examOrderId = null
      this.studentList = []
      getStudentList(this.applyOrderId).then((res) => {
        if (res.code === 200) {
          this.studentList = res.data
        }
      })
    },
    goExam() {
      startExam({examOrderId: this.examOrderId}).then((res) => {
        if (res.code === 200) {
          let paperId = res.data.paperId
          this.$router.push({
            path: '/myCourse-exam',
            query: {
              paperId,
              examOrderId: this.examOrderId,
              applyPrderId: this.applyOrderId,
              hasExam: this.hasExam
            }
          })
        }else {
          return this.$message({type: 'warning', message: res.msg})
        }
      })
    },

    next() {
      if (this.examStep == 1) {
        let params = {
          applyId: this.examStudent
        }
        canExam(params).then((res) => {
          if (res.code === 200) {
            if (res.data.examOrderId) {
              this.examOrderId = res.data.examOrderId
            }
            this.examStep++
            this.examTitle = '请确认考生信息'
            this.studentData = res.data.applyInfo
            this.examData = res.data.examConfig
            this.isPay = res.data.isFree

          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            })
          }
        })
      } else if (this.examStep == 2 && this.isPay != 0) {
        if (!this.examOrderId) {
          let params = {
            applyId: this.examStudent,
            examConfigId: this.examData.id
          }
          getExamOrder(params).then((res) => {
            if (res.code === 200) {
              this.orderNumber = res.data.orderNumber
              this.examOrderId = res.data.examOrderId
              if(res.data.isFree == 1){
                payNative({orderNo: this.orderNumber, payType: this.payType}).then((res) => {
                  if (res.code === 200) {
                    //价格为0的情况返回pay_ok
                    if (res.data.pay_ok) {
                      this.$message({
                        type: 'success',
                        message: '支付成功'
                      })
                      this.examStep = 4
                      this.payResult = 'success'
                      this.$store.commit('hideLoading')
                    } else {
                      this.examStep = 3
                      this.examTitle = '请支付考试费用'
                      this.initWebSocket()
                      this.qrcode = res.data.codeUrl
                      this.orderPrice = res.data.price
                      this.qrcodeVisible = true
                      this.$store.commit('hideLoading')
                    }
                  }else {
                    this.examStep = 2
                    return this.$message({type: 'error', message: '支付失败！'})
                  }
                })
              }
            }
          })
        }
      } else if (this.examStep == 4) {
        if (this.payResult === 'success') {
          this.goExam()
        } else if (this.payResult === 'fail') {
          this.examStep = 1
        }
      }
    },
    prev() {
      this.examStep--
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(() => {
            // done();
            this.$emit('close')
          })
          .catch(() => {
          });
    },
    //webSocket
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        const wsUrl = process.env.VUE_APP_BASE_WSS

        this.websock = new WebSocket(wsUrl)
        this.websock.onmessage = this.onMessage
        this.websock.onopen = this.onOpen
        this.websock.onerror = this.onError
        this.websock.onclose = this.onClose
      }
    },
    onOpen() {
      //连接建立之后执行send方法发送数据
      let params = {
        method: 'pay',
        orderNo: this.orderNumber ? this.orderNumber : this.orderId
      }
      this.onSend(JSON.stringify(params))
    },
    onError() {
      //连接建立失败重连
      setTimeout(() => {
        console.log('连接建立失败重连')
        this.initWebSocket()
      }, 1000)
    },
    onMessage(e) {
      //数据接收

      let redata = JSON.parse(e.data)
      console.log(redata.code)

      if (redata.code == 202) {
        this.$message({
          type: 'warning',
          message: '支付超时，请重新支付'
        })
        this.websock.close()
        this.qrcode = null
        setTimeout(() => {
          this.examStep = 4
          this.payResult = 'fail'
        }, 1500)
      }

      if (redata.code == 201) {
        this.$message({
          type: 'success',
          message: '支付成功'
        })
        this.websock.close()

        setTimeout(() => {
          this.examStep = 4
          this.payResult = 'success'
        }, 1500)
      }
    },
    onSend(Data) {
      //数据发送
      this.websock.send(Data)
    },
    onClose(e) {
      //关闭
      console.log('断开连接', e)
      // this.qrcodeVisible=false
    },

  }
}
</script>

<style scoped lang="scss">
.dialog-wrapper{
  ::v-deep.el-dialog {
    border-radius: 10px;
    //height: 482px;
  }

  ::v-deep.el-dialog__header {
    border-bottom: 1px solid #dcdfe6;
  }
}


.btn {
  display: flex;
  justify-content: right;
  //margin-top: 40px;
}

.exam-content {
  ul {
    padding: 0 40px;

    li {
      margin-bottom: 20px;

      &.pay {
        display: flex;

        .pay-type-group {
          display: flex;
        }

        .icon-weixinzhifu {
          font-size: 20px;
          display: inline-block;

          color: #46bb36;
          padding-right: 5px;

          &.checked {
            transform: scale(1.5);
            transition: all 0.5s;
          }
        }

        .icon-zhifubao {
          font-size: 20px;

          display: inline-block;
          color: #02a9f1;
          padding-right: 5px;

          &.checked {
            transform: scale(1.5);
            transition: all 0.5s;
          }
        }
      }

      .name {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: right;
        width: 70px;
        display: inline-block;
      }

      .value {
        font-size: 14px;
        color: #666666;

        &.red {
          color: #FF1D00;
        }
      }


    }
  }

}

.qr-wrapper {
  .qr-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #666666;
    margin-bottom: 40px;

    .price {
      font-size: 14px;
      color: #666666;

      .red {
        font-size: 22px;
        color: #FF1D00;
      }
    }

  }

  .qr-type {
    margin-bottom: 30px;
    width: 230px;
    height: 56px;
    background: #FFFFFF;
    border: 2px solid #1E50AE;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;

    .type {
      width: 106px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .conner {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .qr-code {
    display: flex;
    justify-content: center;

    .qrcode {
      border: 2px solid #DEDEDE;
      border-radius: 6px;
    }
  }

  .bottom {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 30px;
  }
}

.qr {
  ::v-deep .el-dialog__body {
    padding: 20px 20px 8px 20px;
  }
}

.pay-result {
  .result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }

    span {
      font-size: 14px;
      color: #666666;
      display: inline-block;

      &.iconfont {
        font-size: 66px;
        margin-bottom: 20px;
      }
    }

    .icon-chenggong {
      color: #5bc001;
    }

    .icon-shibai {
      color: #ff3f35;
    }
  }

}

</style>